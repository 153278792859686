<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-08-26 14:31:05
 * @LastEditors: ------
 * @LastEditTime: 2021-09-26 09:08:52
-->
<template>
  <div class="newscenter_container">
    <div class="news_title">
      <img src="@/assets/newsCenter/banner.png" alt="" />
    </div>
    <div class="body">
      <div class="title">
        新闻中心
      </div>
      <van-empty v-if="resultData.length == 0" description="暂无数据" />
      <div class="new_box">
        <div
          class="newsList"
          v-for="(item, index) of resultData"
          :key="index"
          @click="
            $router.push({
              path: '/newsdetail',
              query: {
                id: item.id,
              },
            })
          "
        >
          <div class="list_img">
            <img v-lazy="item.newsImg" alt="" />
          </div>
          <div class="list_bottom">
            <div class="list_title">{{ item.newsTitle }}</div>
            <div class="list_time">{{ item.createTime }}</div>
          </div>
        </div>
      </div>

      <van-pagination
        v-model="currentPage"
        :total-items="total"
        :show-page-size="3"
        force-ellipses
        @change="onChange"
        class="pagination"
      />
    </div>
  </div>
</template>

<script>
import { newsList } from "@/utils/request";
import { Toast } from "vant";
export default {
  name: "newscenter",
  data() {
    return {
      result: null,
      total: 0,
      currentPage: 1,
      resultData: [],
    };
  },
  created() {
    this.getNews();
  },
  methods: {
    onChange(val) {
      //上一页，下一页触发
      this.currentPage = val;
      this.getNews();
      window.scrollTo(0, 0);
    },
    getNews() {
      //获取新闻列表
      let that = this;
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      newsList({
        pageNo: that.currentPage,
      })
        .then((res) => {
          that.total = res.data.result.total;
          that.resultData = res.data.result.records;
          Toast.clear();
        })
        .catch(() => {
          Toast.loading({
            message: "数据加载失败",
            forbidClick: true,
          });
        });
    },
  },
};
</script>

<style scoped lang="less">
.newscenter_container {
  .news_title {
    img {
      width: 100%;
    }
  }
  .body {
    text-align: center;
    .title {
      font-weight: bold;
      font-size: 22px;
      color: var(--themeColor);
      margin: 30px 0 20px 0;
    }
    .new_box {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-left: 7px;
      .newsList {
        font-size: 16px;
        display: inline-block;
        width: 172px;
        margin: 10px 4px 16px 4px;

        .list_img {
          img {
            width: 172px;
            height: 100px;
          }
        }
        .list_bottom {
          display: flex;
          flex-direction: column;
          text-align: left;
          line-height: 1.6;
          color: var(--color333);
          .list_title {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .list_time {
            text-align: left;
          }
        }
      }
    }
  }
}
.pagination {
  margin-top: 50px;
}
/deep/ .van-pagination__item {
  color: rgb(0, 0, 0) !important;
}
/deep/ .van-pagination__item--active {
  background-color: var(--bilibiColor);
}
/deep/ .van-pagination__item--active {
  color: var(--white) !important;
}
</style>
