/*
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version:
 * @Author: Duanshuai
 * @Date: 2021-08-19 14:10:10
 * @LastEditors: ------
 * @LastEditTime: 2021-10-27 13:13:59
 */
import axios from "axios";

const request = axios.create({
    // baseURL: "http://192.168.0.238:6102/gateway-ext",
    baseURL: "https://api.aijiehu.cn/gateway-ext",
    timeout: 5000,
});

export const newsList = (params) => {
    //新闻列表
    return request({
        method: "GET",
        url: "/news/news/platList",
        params,
    });
};
export const newsDetail = (params) => {
    //新闻详情
    return request({
        method: "GET",
        url: "/news/news/detail",
        params,
    });
};
export const commitMsg = (params) => {
    //提交合作
    return request({
        method: "POST",
        url: "/ajh/storeApply/add",
        data: params,
    });
};
export const getHistoryList = () => {
    //发展历程
    return request({
        method: "GET",
        url: "/history/list?pageSize=100",
    });
};
export const getShowList = () => {
    return request({
        method: "GET",
        url: `/style/list1?pageSize=100`,
    });
};
export const postAddConsult = (params) => {
    return request({
        method: "POST",
        url: `/consumer/apply/add`,
        data: params,
    });
};